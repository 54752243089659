@media  screen and (max-width: 600px){
    .container{
        padding: 1rem 1rem;
    }
    .flex{
        flex-direction: column;
        flex-wrap: wrap;
    }
    .banner{
        margin-top: -19rem;
        height: 1030px;
    }
    .navbar{
        flex-direction: row;

    }
    .banner-con{
        top:28%;
        text-align: center;
    }
    .banner img{
        width: 90%;
        margin-top: 4rem;   
    }
    .services{
        flex-direction: row;
        height: fit-content;
    }
    .service{
        width: 90%;
        height: 200px;
        margin-left: 1.5rem;
        margin-top: 1rem;
    }
    .text{
        width: 100%;
        margin-top: 1rem;
    }
    .text div{
        margin: 0
       }
    .All-serve{
        height: 300px;
    }
    .v-line{
        height: 40px;
    }
    .counter{
        height: 550px;
    }
    .plan-card{
        display: inline;
        margin-top: 1rem; 
    }
    .footer .container{
        flex-direction: column;
        line-height: 20px;
    }

}