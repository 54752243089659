*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container{
    padding: 0 4rem;
}
.logo img{
  height: 90px;
}
.banner{
  z-index: -1;
  background-color: #120a8f;
  margin-top: -7rem;
  height: 700px;
}
.banner-con{
  color: white;
  position: relative;
  line-height: 30px;
  top: 25%;
}
.banner img{
  width: 38%;
}
.flex{
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.navbar{
  padding-top: 2rem;
  position: relative;
}
.navbar ul{
  list-style-type: none;
}
.navbar ul li{
  margin-right: 2rem;
  font-size: 20px;
  cursor: pointer;
}
.services{
  height: 300px;
  margin-top: -8rem;
  justify-content: space-around;
}
.service{
  background-color:white;
  width: 30%;
  height: fit-content;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 1px silver;
  cursor: pointer;
}
.btn-2{
  padding: 0.8rem 3rem;
  font-size: 20px;
  color: white;
  background-color: #0073cf;
  border: none;
  margin-top: 1rem;
  outline: none;
  border-radius: 5px
}
.btn-2:hover{
  background-color:#0892d0; 
}
.s-middle{
  margin-top: 7rem;
}
.text{
  width: 47%;
  margin-top: -2rem
}
.text div{
 margin-top: 6rem;
}
.All-serve{
  width:260px;
  height: 280px;
}
.mt{
  margin-top: 8rem;
}
.counter{
  height: 200px; 
  color: white;
  opacity: 0.89;
  background-color: blue;
  line-height: 10px;
}
.counter h1{
  font-size: 50px;
  font-weight: 600;
}
.counter p{
  font-size: 20px;
  font-weight: 600;
}
.v-line{
  width: 1px;
  background-color: white;
  opacity: 0.2;
  height: 220px;
}
.team{
  margin-top: 3rem;
  line-height: 30px;
  
}
.team img{
  width: 50%;
  transition: transform .2s;
  border-radius: 100px
}
.team img:hover{
  transform: scale(1.3)
}
.plan-card{
  background-color:white;
  padding: 4rem;
  box-shadow: 1px 1px 10px 1px silver;
  line-height: 40px;
  border-radius: 7px;
}
.cards{
  margin-top: 3rem;
}
.cards h2,span{
  font-weight: 700;
}
.contact{
  padding: 6rem;
  color: white;
}
.footer{
  background-color: darkblue;
  line-height: 35px
}
.footer h1{
  color: white;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 0.7rem;
}
.footer ul li{
  list-style-type: none;
  color: white;
  margin-left: -2rem;
}
.hr{
  margin-top: -2rem;
  overflow: hidden;  
  height: 2px;
  background-color: #89CFF0;
}


/* about */
.about-banner{
  margin-top: -7rem;
  padding:7rem 0;
}
.about-team{
  margin-top: 3rem;
}
.about-team img{
  width: 48%
}
.about-team div{
  margin-left: 3rem;
}
.divider{
  width: 13%;
  background-color:red;
  height: 3px;
  border: none;
  margin-top: 1.5rem;
}